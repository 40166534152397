.runner_stones_container {
    display: flex;
    // justify-content: center;
    width: fit-content;

    .content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: row;
        background-image: url('/images/running-stones-logo.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        // .icon {}

        // .stones_quantity {
        //     z-index: 2;
        //     position: absolute;
        //     top: 13px;
        //     left: 19px;
        // }
    }
}
