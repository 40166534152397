@import '../../../styles/common/common.scss';

$slide-item-max-width: 350px !default;
$slide-item-margin-right: 25px !default;

.root {
    max-width: 100vw;
    overflow: hidden;
    background: linear-gradient(
        to bottom,
        var(--utmb-color-primary) 0%,
        var(--utmb-color-primary) 50%,
        var(--utmb-color-grey) 50%,
        var(--utmb-color-grey) 100%
    );
}

.container {
    width: 100%;
    color: var(--utmb-color-default);
    text-align: left;
    display: flex;
    justify-content: center;
    padding: $slide-item-margin-right;
    z-index: 99 !important;
    overflow: hidden !important;

    :global .embla__container {
        @include bp(max-width md) {
            width: 100% !important;
        }
    }

    .item {
        position: relative;
        background-color: var(--utmb-color-white);
        box-shadow: none;
        margin-right: $slide-item-margin-right;
        overflow: visible;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 80vw;

        @include bp(md) {
            flex: 0 0 rem($slide-item-max-width);
        }

        @include bp(lg) {
            box-sizing: border-box !important;
        }

        &:hover,
        &:focus-within {
            box-shadow: 0 0 rem(35px) rgba(0, 0, 0, 0.17);
        }

        .image {
            position: relative;
            height: rem(170px);
            overflow: hidden;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to bottom, rgba(0 0 0 / 10%) 0%, rgba(0 0 0 / 10%) 70%, rgba(0 0 0 / 30%) 75%, rgba(0 0 0 / 80%) 100%);
                z-index: 0;
        
                @include bp(xl) {
                    background-image: linear-gradient(to bottom, rgba(0 0 0 / 10%) 0%, rgba(0 0 0 / 10%) 65%, rgba(0 0 0 / 30%) 85%, rgba(0 0 0 / 80%) 100%);
                }
            }

            .date {
                position: absolute;
                top: 0;
                left: 0;
                transform: skew(-24deg);
                transform-origin: top right;
                min-width: rem(120px);
                text-align: center;
                padding: rem(2px) rem(16px);
                
                & > span {
                    display: inline-block;
                    transform: skew(24deg);
                    text-transform: capitalize;
                    font-weight: 700;
                }
            }

            .status {
                position: absolute;
                bottom: rem(5px);
                left: 0;
                z-index: 2;
                width: 100%;
                text-align: center;
                color: var(--utmb-color-white);
                text-transform: uppercase;

                .dot {
                    display: inline-flex;
                    margin-right: rem(5px);
                    border-radius: 50%;
                    width: rem(10px);
                    height: rem(10px);
                }
            }
        }

        .content {
            position: relative;
            padding: rem(10px) rem(15px) rem(25px) rem(15px);
            border-bottom: 4px solid var(--utmb-color-white);
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .logo {
                max-height: 50px !important;
                object-position: left;
            }

            .statLabel {
                font-size: Oxanium;
                font-weight: 700;
                color: var(--utmb-color-blue-light);
                font-size: 1.2rem;
                font-style: normal;
            }
            .statValue {
                font-size: Oxanium;
                font-weight: 700;
                color: var(--utmb-color-ws-primary);
                font-size: 1.6rem;
                font-style: normal;
            }

            .cols {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: rem(10px);
                > div {
                    width: 50%;
                    margin-bottom: rem(10px);

                    &:nth-child(even) {
                        text-align: right;
                        >p>div {
                            float:right;
                        }
                    }
                    &:nth-child(odd) {
                        img {
                            -o-object-position: left center !important;
                            object-position: left center !important;
                        }
                    }
                }
                
            }
            .footer {
                display: flex;
                flex-direction: column;
                align-items: center;

                a:hover {
                    text-decoration: none;
                }

                .btn {
                    text-decoration: none;
                    > div {
                        padding: 1.5rem 3rem;
                    }

                    &.disabled > div {
                        border: 1px solid var(--utmb-color-primary);
                        background-color: var(--utmb-color-white) !important;
                        color: var(--utmb-color-default);
                    }

                    &:not(.disabled):hover > div {
                        box-shadow: 0 0 1.5rem rgb(0 0 0 / 50%);
                        filter: brightness(1.2);
                    }
                }
            }
        }

        &_offset {
            margin-top: rem(80px);

            @include bp(md) {
                margin-top: rem(80px);
            }
        }
    }

}


//////////
// Dots //
//////////

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .mob_pad;
    opacity: 0;

    &.showdots {
        opacity: 1 !important;
        flex-wrap: wrap;
    }
}

:export {
    slideItemMaxWidth: $slide-item-max-width;
    slideItemMarginRight: $slide-item-margin-right;
}

