@import 'common/common.scss';

.wss_stat {
    text-transform: uppercase;
    color: var(--utmb-color-ws-primary);
    padding-left: 0;
    text-align: left;
    margin-bottom: rem(10px);

    @include bp(xl) {
        margin-bottom: 0;
    }

    .wss_label {
        line-height: rem(15px);
        letter-spacing: 0.3px;

        @include bp(xl) {
            line-height: rem(20px);
            letter-spacing: 0.6px;
        }
    }

    .wss_category {
        & img {
            object-position: right center !important;

            @include bp(xl) {
                object-position: left center !important;
            }
        }
    }

    .wss_value {
        line-height: rem(20px);
        letter-spacing: 0.4px;

        img {
            max-width: 11rem;
        }

        @include bp(xl) {
            /*line-height: rem(28px);*/
            letter-spacing: 0.8px;
        }

        .wss_icon {
            & svg {
                transform: scale(1.2);
            }
        }
    }
}
